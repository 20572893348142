import '../sdist/tailwind.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './main.scss';

import 'bootstrap';
import $ from 'jquery';
import Swiper from 'swiper';
import URI from 'urijs';
import CookiesEuBanner from 'cookies-eu-banner';
import './js/photoswipe.js';

$(document).ready(function() {

  var banner = new CookiesEuBanner(function(){
      (function(b,o,i,l,e,r){b.GoogleAnalyticsObject=l;b[l]||(b[l]=
      function(){(b[l].q=b[l].q||[]).push(arguments)});b[l].l=+new Date;
      e=o.createElement(i);r=o.getElementsByTagName(i)[0];
      e.src='https://www.google-analytics.com/analytics.js';
      r.parentNode.insertBefore(e,r)}(window,document,'script','ga'));
    ga('create','UA-126236618-1', 'auto');ga('send','pageview');ga('set', 'anonymizeIp', true);
  });

  try {
    var photogalleryOptions = {
      shareButtons: localizedShareButtons
    };
  } catch (ReferenceError) {
    var photogalleryOptions = {};
  }

  $.extend(photogalleryOptions, {
    itemFilter: function () {
      return $(this).parents('.slick-cloned').length === 0;
    },
    captionSelector: '.caption'
  });

  $('.gallery').photogallery(photogalleryOptions);

  var homeNewsSwiper = new Swiper('.homenews', {
    slidesPerView: 'auto',
    slidesOffsetAfter: 80,
    navigation: {
      prevEl: '.homenews__arrow--prev',
      nextEl: '.homenews__arrow--next',
      disabledClass: 'homenews__arrow--disabled',
    },
  });

  let home__slider = new Swiper('.home__slider', {
    slidesPerView: 1,
    speed: 500,
    navigation: {
      prevEl: '.home__slider__arrow--prev',
      nextEl: '.home__slider__arrow--next',
      disabledClass: 'home__slider__arrow--disabled',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  });

  if ($('.streamteaser .swiper-slide').length > 1) {

    var streamTeaserSwiper = new Swiper('.streamteaser', {
      slidesPerView: 1,
      speed: 500,
      navigation: {
        prevEl: '.streamteaser__arrow--prev',
        nextEl: '.streamteaser__arrow--next',
        disabledClass: 'streamteaser__arrow--disabled',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    });

    $('.streamteaser').hover(function (params) {
      streamTeaserSwiper.autoplay.stop();
    }, function (e) {
      streamTeaserSwiper.autoplay.start();
    });
  }

  if ($('#StreamFilter').length >= 1) {
    $('#StreamFilter').on('change', '.stream-filter__select', function () {
      var uri = new URI();
      uri.query($('#StreamFilter').serialize());
      window.location.href = uri;
    });
  }

  if ($('.slide-gallery .swiper-slide').length > 1) {

    var gallerySwiper = new Swiper('.slide-gallery', {
      slidesPerView: 1,
      speed: 500,
      navigation: {
        prevEl: '.slide-gallery__arrow--prev',
        nextEl: '.slide-gallery__arrow--next',
        disabledClass: 'slide-gallery__arrow--disabled',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    });

    $('.slide-gallery').hover(function (params) {
      gallerySwiper.autoplay.stop();
    }, function (e) {
      gallerySwiper.autoplay.start();
    });
  }
});

import Vue from 'vue';
import NewsApp from './js/NewsApp.vue';
import TZMap from "./js/map/TZMap.vue";
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDeySUzkx6-T6dCkT3P84b03p7EP8j1QBU',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})


var VueTZMap = $('#TZMap');

if (VueTZMap.length) {

  new Vue({
    el: '#TZMap',
    components: {
      TZMap
    }
  });
}

var VueNewsApp = $('#NewsApp');

if (VueNewsApp.length) {

  new Vue({
    el: '#NewsApp',
    components: {
      NewsApp
    }
  });

}
