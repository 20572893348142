export default [{lng: 12.7275907, lat: 50.8170908},
{lng: 12.7287901, lat: 50.8189047},
{lng: 12.731847, lat: 50.8203998},
{lng: 12.7351288, lat: 50.822619},
{lng: 12.7367509, lat: 50.8232745},
{lng: 12.7367943, lat: 50.8255433},
{lng: 12.736776, lat: 50.8272027},
{lng: 12.736998, lat: 50.8277219},
{lng: 12.737352, lat: 50.8285496},
{lng: 12.7377941, lat: 50.8288951},
{lng: 12.7385449, lat: 50.8291515},
{lng: 12.7388092, lat: 50.8293333},
{lng: 12.7396751, lat: 50.8295062},
{lng: 12.7401664, lat: 50.8294433},
{lng: 12.7409439, lat: 50.8293214},
{lng: 12.7417523, lat: 50.8290486},
{lng: 12.7417342, lat: 50.8283149},
{lng: 12.744012, lat: 50.8281559},
{lng: 12.7449747, lat: 50.8283168},
{lng: 12.7457486, lat: 50.8279353},
{lng: 12.7463338, lat: 50.8275239},
{lng: 12.7472965, lat: 50.8273034},
{lng: 12.7480317, lat: 50.8270182},
{lng: 12.7484572, lat: 50.8263158},
{lng: 12.7488653, lat: 50.8257099},
{lng: 12.7491054, lat: 50.8253525},
{lng: 12.7497026, lat: 50.824979},
{lng: 12.7508927, lat: 50.8243376},
{lng: 12.7528461, lat: 50.824382},
{lng: 12.7530349, lat: 50.8248053},
{lng: 12.7535456, lat: 50.8243586},
{lng: 12.7545221, lat: 50.8228075},
{lng: 12.7551219, lat: 50.8225217},
{lng: 12.7563247, lat: 50.8230006},
{lng: 12.7572481, lat: 50.8237322},
{lng: 12.7612367, lat: 50.8226172},
{lng: 12.7619446, lat: 50.8220925},
{lng: 12.7617747, lat: 50.8220627},
{lng: 12.7622713, lat: 50.8213072},
{lng: 12.764191, lat: 50.8217467},
{lng: 12.7651348, lat: 50.8215678},
{lng: 12.7657011, lat: 50.8217407},
{lng: 12.7649177, lat: 50.8224622},
{lng: 12.7666152, lat: 50.8230137},
{lng: 12.7651033, lat: 50.8242047},
{lng: 12.7644715, lat: 50.8246672},
{lng: 12.7631441, lat: 50.8259041},
{lng: 12.7649931, lat: 50.8264459},
{lng: 12.7633415, lat: 50.8281916},
{lng: 12.7624899, lat: 50.8293947},
{lng: 12.7648422, lat: 50.8301172},
{lng: 12.7658951, lat: 50.8302275},
{lng: 12.7678813, lat: 50.8281678},
{lng: 12.7702975, lat: 50.8258308},
{lng: 12.772142, lat: 50.824672},
{lng: 12.7737084, lat: 50.8255208},
{lng: 12.774271, lat: 50.8263554},
{lng: 12.774589, lat: 50.8272001},
{lng: 12.7747712, lat: 50.8275001},
{lng: 12.7756679, lat: 50.8282453},
{lng: 12.7777463, lat: 50.8295251},
{lng: 12.7761777, lat: 50.8304245},
{lng: 12.7785814, lat: 50.8323239},
{lng: 12.7797375, lat: 50.8313672},
{lng: 12.7805473, lat: 50.8300755},
{lng: 12.7821369, lat: 50.8300306},
{lng: 12.7830859, lat: 50.8302863},
{lng: 12.7846461, lat: 50.8306856},
{lng: 12.7854354, lat: 50.8310018},
{lng: 12.7864612, lat: 50.8317595},
{lng: 12.787781, lat: 50.8325684},
{lng: 12.7861146, lat: 50.8335722},
{lng: 12.7845208, lat: 50.8348235},
{lng: 12.7837138, lat: 50.8358458},
{lng: 12.782818, lat: 50.8366266},
{lng: 12.7831145, lat: 50.8368918},
{lng: 12.78465, lat: 50.8373431},
{lng: 12.7865622, lat: 50.8378176},
{lng: 12.7870408, lat: 50.8379975},
{lng: 12.7884059, lat: 50.8386902},
{lng: 12.7888941, lat: 50.8382258},
{lng: 12.7894571, lat: 50.8376518},
{lng: 12.7901554, lat: 50.8370319},
{lng: 12.7905897, lat: 50.8375147},
{lng: 12.7916372, lat: 50.8391598},
{lng: 12.792128, lat: 50.8397498},
{lng: 12.7923234, lat: 50.8402821},
{lng: 12.7924017, lat: 50.8411504},
{lng: 12.7926276, lat: 50.8415623},
{lng: 12.7935754, lat: 50.8422335},
{lng: 12.7953842, lat: 50.8427417},
{lng: 12.7963273, lat: 50.8432503},
{lng: 12.7981997, lat: 50.8438277},
{lng: 12.7989613, lat: 50.8440529},
{lng: 12.8001316, lat: 50.8444998},
{lng: 12.8010293, lat: 50.8452119},
{lng: 12.8023024, lat: 50.8459122},
{lng: 12.8028066, lat: 50.8463394},
{lng: 12.8045754, lat: 50.8471637},
{lng: 12.8058994, lat: 50.8472404},
{lng: 12.8061082, lat: 50.8472733},
{lng: 12.8044827, lat: 50.851393},
{lng: 12.8043002, lat: 50.8529041},
{lng: 12.8043965, lat: 50.8531523},
{lng: 12.8055974, lat: 50.8562485},
{lng: 12.8060372, lat: 50.8585604},
{lng: 12.8058676, lat: 50.8588707},
{lng: 12.805692, lat: 50.8591433},
{lng: 12.8054441, lat: 50.8594054},
{lng: 12.804831, lat: 50.8596182},
{lng: 12.8047974, lat: 50.8595564},
{lng: 12.804109, lat: 50.8599443},
{lng: 12.8047674, lat: 50.860506},
{lng: 12.8057388, lat: 50.8609606},
{lng: 12.8055857, lat: 50.8612658},
{lng: 12.8054496, lat: 50.8619065},
{lng: 12.8054352, lat: 50.8619742},
{lng: 12.8052958, lat: 50.86437},
{lng: 12.8052927, lat: 50.8644215},
{lng: 12.8053508, lat: 50.8657982},
{lng: 12.805417, lat: 50.8674542},
{lng: 12.807163, lat: 50.8681804},
{lng: 12.8088928, lat: 50.8691793},
{lng: 12.8146489, lat: 50.8723781},
{lng: 12.8168185, lat: 50.8746335},
{lng: 12.8173848, lat: 50.8753601},
{lng: 12.818121, lat: 50.876444},
{lng: 12.819226, lat: 50.8774374},
{lng: 12.8199237, lat: 50.8773135},
{lng: 12.8219529, lat: 50.8770753},
{lng: 12.8229996, lat: 50.8768875},
{lng: 12.8235354, lat: 50.8768233},
{lng: 12.8243151, lat: 50.8769237},
{lng: 12.8247397, lat: 50.8771933},
{lng: 12.824713, lat: 50.8781718},
{lng: 12.8247309, lat: 50.8785134},
{lng: 12.8250946, lat: 50.8792173},
{lng: 12.8260605, lat: 50.8808878},
{lng: 12.8269007, lat: 50.882123},
{lng: 12.8273908, lat: 50.8831636},
{lng: 12.8272132, lat: 50.8839912},
{lng: 12.8275823, lat: 50.8842529},
{lng: 12.8279635, lat: 50.8845278},
{lng: 12.8283773, lat: 50.8847906},
{lng: 12.8309616, lat: 50.886695},
{lng: 12.8325954, lat: 50.8873733},
{lng: 12.833889, lat: 50.8875909},
{lng: 12.8356398, lat: 50.8876086},
{lng: 12.8375686, lat: 50.8872243},
{lng: 12.8387812, lat: 50.8866982},
{lng: 12.8394749, lat: 50.8861414},
{lng: 12.8397334, lat: 50.8862393},
{lng: 12.8389644, lat: 50.8878724},
{lng: 12.8377866, lat: 50.8901993},
{lng: 12.8370548, lat: 50.891644},
{lng: 12.8371289, lat: 50.8916549},
{lng: 12.8376447, lat: 50.8916967},
{lng: 12.8394761, lat: 50.8923573},
{lng: 12.841507, lat: 50.8936593},
{lng: 12.843758, lat: 50.895094},
{lng: 12.8460807, lat: 50.8963283},
{lng: 12.846912, lat: 50.8970412},
{lng: 12.847061, lat: 50.897615},
{lng: 12.8469489, lat: 50.8980155},
{lng: 12.8491063, lat: 50.8979004},
{lng: 12.8501421, lat: 50.8978444},
{lng: 12.8514886, lat: 50.8979389},
{lng: 12.8540731, lat: 50.8981681},
{lng: 12.8549301, lat: 50.8980273},
{lng: 12.8561266, lat: 50.8983129},
{lng: 12.8592733, lat: 50.8985992},
{lng: 12.8611744, lat: 50.898518},
{lng: 12.861669, lat: 50.8989036},
{lng: 12.8622649, lat: 50.8988538},
{lng: 12.8627701, lat: 50.898781},
{lng: 12.862945, lat: 50.8989026},
{lng: 12.8632611, lat: 50.8990377},
{lng: 12.8636469, lat: 50.899189},
{lng: 12.8640151, lat: 50.8993402},
{lng: 12.8643326, lat: 50.8994915},
{lng: 12.8645527, lat: 50.8996506},
{lng: 12.8650064, lat: 50.9001378},
{lng: 12.8651606, lat: 50.9002828},
{lng: 12.8654546, lat: 50.900493},
{lng: 12.8658236, lat: 50.9006751},
{lng: 12.8664141, lat: 50.9008358},
{lng: 12.8668922, lat: 50.9010028},
{lng: 12.8677894, lat: 50.9012802},
{lng: 12.868612, lat: 50.9016135},
{lng: 12.8691905, lat: 50.901873},
{lng: 12.869201, lat: 50.9019468},
{lng: 12.8699148, lat: 50.9019199},
{lng: 12.8706808, lat: 50.901537},
{lng: 12.8709586, lat: 50.9013622},
{lng: 12.8708534, lat: 50.9010191},
{lng: 12.8710522, lat: 50.9008683},
{lng: 12.8712453, lat: 50.9006819},
{lng: 12.8715411, lat: 50.9002841},
{lng: 12.8716885, lat: 50.9003609},
{lng: 12.8718378, lat: 50.9004031},
{lng: 12.8719519, lat: 50.9004293},
{lng: 12.8721295, lat: 50.900508},
{lng: 12.8721227, lat: 50.9006321},
{lng: 12.872237, lat: 50.9006531},
{lng: 12.8723196, lat: 50.9006099},
{lng: 12.8723411, lat: 50.9005426},
{lng: 12.8723996, lat: 50.9004928},
{lng: 12.8724944, lat: 50.9004622},
{lng: 12.872771, lat: 50.9005318},
{lng: 12.8728933, lat: 50.90054},
{lng: 12.8730514, lat: 50.9005829},
{lng: 12.8732559, lat: 50.9006687},
{lng: 12.8735865, lat: 50.9008914},
{lng: 12.8743257, lat: 50.9014489},
{lng: 12.875513, lat: 50.9014377},
{lng: 12.8769039, lat: 50.9012971},
{lng: 12.8770813, lat: 50.9012792},
{lng: 12.877147, lat: 50.9011682},
{lng: 12.8794037, lat: 50.9013275},
{lng: 12.8804631, lat: 50.9013944},
{lng: 12.8820705, lat: 50.901496},
{lng: 12.8823087, lat: 50.9015369},
{lng: 12.8825132, lat: 50.9016079},
{lng: 12.8834226, lat: 50.9018275},
{lng: 12.8842083, lat: 50.9021543},
{lng: 12.8851298, lat: 50.9029591},
{lng: 12.8855249, lat: 50.9032153},
{lng: 12.8860415, lat: 50.9034606},
{lng: 12.8869126, lat: 50.9036361},
{lng: 12.8876172, lat: 50.9037369},
{lng: 12.8878509, lat: 50.9038614},
{lng: 12.8881492, lat: 50.9039377},
{lng: 12.8883694, lat: 50.9036491},
{lng: 12.8886118, lat: 50.9034362},
{lng: 12.8892168, lat: 50.9029879},
{lng: 12.8898236, lat: 50.9026936},
{lng: 12.8902185, lat: 50.9025818},
{lng: 12.8906387, lat: 50.9024922},
{lng: 12.8910067, lat: 50.9024515},
{lng: 12.8913831, lat: 50.9024021},
{lng: 12.8917092, lat: 50.9023781},
{lng: 12.892004, lat: 50.902372},
{lng: 12.8923523, lat: 50.902489},
{lng: 12.8924619, lat: 50.9025644},
{lng: 12.8928251, lat: 50.9027014},
{lng: 12.8940157, lat: 50.9025137},
{lng: 12.8946249, lat: 50.9023997},
{lng: 12.8950849, lat: 50.9024079},
{lng: 12.895138, lat: 50.9021514},
{lng: 12.8952975, lat: 50.9019739},
{lng: 12.8953433, lat: 50.9018096},
{lng: 12.895622, lat: 50.9017217},
{lng: 12.8957865, lat: 50.9016094},
{lng: 12.8961122, lat: 50.9011033},
{lng: 12.8964537, lat: 50.9011254},
{lng: 12.8967577, lat: 50.9012339},
{lng: 12.8969971, lat: 50.9014687},
{lng: 12.8971207, lat: 50.9016907},
{lng: 12.8967641, lat: 50.9021909},
{lng: 12.8966797, lat: 50.9024749},
{lng: 12.8974227, lat: 50.9023642},
{lng: 12.897628, lat: 50.9023003},
{lng: 12.8977908, lat: 50.9022289},
{lng: 12.8978662, lat: 50.9020998},
{lng: 12.8979389, lat: 50.901649},
{lng: 12.8979349, lat: 50.9013505},
{lng: 12.8979734, lat: 50.901057},
{lng: 12.8985366, lat: 50.9002889},
{lng: 12.8986813, lat: 50.900055},
{lng: 12.8987356, lat: 50.8998325},
{lng: 12.8987095, lat: 50.8997075},
{lng: 12.8986269, lat: 50.8995727},
{lng: 12.8984701, lat: 50.8994623},
{lng: 12.8982019, lat: 50.8994047},
{lng: 12.8975999, lat: 50.8993534},
{lng: 12.8973336, lat: 50.899302},
{lng: 12.8971732, lat: 50.899261},
{lng: 12.897058, lat: 50.8991872},
{lng: 12.8968786, lat: 50.89908},
{lng: 12.8956862, lat: 50.8977753},
{lng: 12.8957092, lat: 50.8977242},
{lng: 12.8957937, lat: 50.8975369},
{lng: 12.8959047, lat: 50.8971493},
{lng: 12.8959885, lat: 50.8968568},
{lng: 12.8959891, lat: 50.8967892},
{lng: 12.8959909, lat: 50.8965978},
{lng: 12.8961578, lat: 50.8963281},
{lng: 12.8966324, lat: 50.8962219},
{lng: 12.8968769, lat: 50.8960596},
{lng: 12.8968903, lat: 50.8958355},
{lng: 12.8967466, lat: 50.8956501},
{lng: 12.8945813, lat: 50.8950467},
{lng: 12.8945282, lat: 50.8949747},
{lng: 12.8946004, lat: 50.8948876},
{lng: 12.8947175, lat: 50.8948177},
{lng: 12.8948223, lat: 50.8947841},
{lng: 12.8951008, lat: 50.8948265},
{lng: 12.8955427, lat: 50.8947397},
{lng: 12.8957162, lat: 50.8946421},
{lng: 12.8957795, lat: 50.8945719},
{lng: 12.895777, lat: 50.8944764},
{lng: 12.8957258, lat: 50.8943461},
{lng: 12.89573, lat: 50.8942149},
{lng: 12.8958442, lat: 50.8940025},
{lng: 12.8955998, lat: 50.8937491},
{lng: 12.8955943, lat: 50.8934561},
{lng: 12.8951785, lat: 50.8930744},
{lng: 12.8947484, lat: 50.8927087},
{lng: 12.8942534, lat: 50.8921473},
{lng: 12.8939934, lat: 50.8918121},
{lng: 12.8938994, lat: 50.8915585},
{lng: 12.8940839, lat: 50.8915985},
{lng: 12.8946766, lat: 50.8917396},
{lng: 12.8953146, lat: 50.8918959},
{lng: 12.8956047, lat: 50.8919615},
{lng: 12.8966541, lat: 50.892183},
{lng: 12.8970128, lat: 50.8922497},
{lng: 12.8982405, lat: 50.8922731},
{lng: 12.8999834, lat: 50.8923048},
{lng: 12.9002067, lat: 50.8923093},
{lng: 12.9005602, lat: 50.8923643},
{lng: 12.9013096, lat: 50.8923858},
{lng: 12.9020548, lat: 50.8923951},
{lng: 12.9035776, lat: 50.8923552},
{lng: 12.9043255, lat: 50.8923418},
{lng: 12.9057755, lat: 50.8922863},
{lng: 12.9067749, lat: 50.8921296},
{lng: 12.9077395, lat: 50.8919539},
{lng: 12.9094385, lat: 50.8916037},
{lng: 12.9108164, lat: 50.8911696},
{lng: 12.9109512, lat: 50.8911165},
{lng: 12.9114006, lat: 50.8909682},
{lng: 12.9121559, lat: 50.8907219},
{lng: 12.9128521, lat: 50.8905118},
{lng: 12.9136401, lat: 50.8902237},
{lng: 12.9150069, lat: 50.8895981},
{lng: 12.9161843, lat: 50.8891897},
{lng: 12.9188001, lat: 50.8882654},
{lng: 12.920007, lat: 50.8871794},
{lng: 12.9203898, lat: 50.886887},
{lng: 12.9206085, lat: 50.8866106},
{lng: 12.920919, lat: 50.8862275},
{lng: 12.9218833, lat: 50.8862109},
{lng: 12.922312, lat: 50.8863088},
{lng: 12.9228295, lat: 50.8863511},
{lng: 12.9235378, lat: 50.8864212},
{lng: 12.9239164, lat: 50.8864508},
{lng: 12.924118, lat: 50.8864557},
{lng: 12.9242472, lat: 50.8892601},
{lng: 12.9263343, lat: 50.8894215},
{lng: 12.9264038, lat: 50.8899025},
{lng: 12.927492, lat: 50.8898902},
{lng: 12.9277153, lat: 50.8898947},
{lng: 12.9281196, lat: 50.8899027},
{lng: 12.9282502, lat: 50.8898602},
{lng: 12.9288527, lat: 50.8898445},
{lng: 12.9295184, lat: 50.8897818},
{lng: 12.9303451, lat: 50.889577},
{lng: 12.9304973, lat: 50.8906782},
{lng: 12.9305844, lat: 50.8907298},
{lng: 12.931682, lat: 50.8903507},
{lng: 12.9316412, lat: 50.8894265},
{lng: 12.931627, lat: 50.8891062},
{lng: 12.9315757, lat: 50.8888158},
{lng: 12.9318429, lat: 50.88885},
{lng: 12.9320419, lat: 50.8889269},
{lng: 12.93257, lat: 50.8889874},
{lng: 12.9330388, lat: 50.8889989},
{lng: 12.9334762, lat: 50.8889428},
{lng: 12.9336759, lat: 50.8889291},
{lng: 12.9342967, lat: 50.8888946},
{lng: 12.9354615, lat: 50.88883},
{lng: 12.9368841, lat: 50.8888454},
{lng: 12.9377473, lat: 50.8890315},
{lng: 12.9379918, lat: 50.8893973},
{lng: 12.9380002, lat: 50.8895818},
{lng: 12.9381279, lat: 50.8898102},
{lng: 12.9384513, lat: 50.8899132},
{lng: 12.9389421, lat: 50.8900655},
{lng: 12.9392656, lat: 50.8900919},
{lng: 12.9397035, lat: 50.890309},
{lng: 12.9401355, lat: 50.8905017},
{lng: 12.9406311, lat: 50.8906881},
{lng: 12.9412603, lat: 50.890831},
{lng: 12.9415561, lat: 50.8908982},
{lng: 12.9419758, lat: 50.8909935},
{lng: 12.9421104, lat: 50.8910241},
{lng: 12.942213, lat: 50.8910129},
{lng: 12.9430072, lat: 50.8909262},
{lng: 12.9431811, lat: 50.8909115},
{lng: 12.9435931, lat: 50.8907039},
{lng: 12.9437086, lat: 50.8905326},
{lng: 12.9438608, lat: 50.8902525},
{lng: 12.9442166, lat: 50.8893174},
{lng: 12.944339, lat: 50.8893137},
{lng: 12.9454562, lat: 50.8895333},
{lng: 12.9454282, lat: 50.8899216},
{lng: 12.9451157, lat: 50.8909948},
{lng: 12.9449331, lat: 50.8919321},
{lng: 12.9450188, lat: 50.891941},
{lng: 12.9449437, lat: 50.8926918},
{lng: 12.946763, lat: 50.8926707},
{lng: 12.9466858, lat: 50.8899845},
{lng: 12.9482111, lat: 50.8899135},
{lng: 12.9480621, lat: 50.8894401},
{lng: 12.9480142, lat: 50.8891434},
{lng: 12.9480457, lat: 50.8886965},
{lng: 12.9482658, lat: 50.8871852},
{lng: 12.9498172, lat: 50.887289},
{lng: 12.9503356, lat: 50.887239},
{lng: 12.9506248, lat: 50.8871308},
{lng: 12.9511697, lat: 50.8872722},
{lng: 12.9518647, lat: 50.8875364},
{lng: 12.9527667, lat: 50.889604},
{lng: 12.9529249, lat: 50.890122},
{lng: 12.9536313, lat: 50.8901535},
{lng: 12.9540322, lat: 50.8901345},
{lng: 12.955338, lat: 50.8901435},
{lng: 12.9554695, lat: 50.8905642},
{lng: 12.9535022, lat: 50.8908495},
{lng: 12.9531542, lat: 50.8909313},
{lng: 12.9531502, lat: 50.8919612},
{lng: 12.9532652, lat: 50.8927211},
{lng: 12.9537582, lat: 50.8925572},
{lng: 12.9550479, lat: 50.8921183},
{lng: 12.9558665, lat: 50.8918869},
{lng: 12.9562702, lat: 50.8917815},
{lng: 12.9564736, lat: 50.8917612},
{lng: 12.9571702, lat: 50.8916649},
{lng: 12.9578555, lat: 50.8922786},
{lng: 12.9578536, lat: 50.8923663},
{lng: 12.9579348, lat: 50.8923734},
{lng: 12.9601613, lat: 50.8925373},
{lng: 12.962115, lat: 50.8926523},
{lng: 12.9645755, lat: 50.8927973},
{lng: 12.9653615, lat: 50.8937811},
{lng: 12.9660043, lat: 50.8943571},
{lng: 12.9667312, lat: 50.8947921},
{lng: 12.9674601, lat: 50.895231},
{lng: 12.9687949, lat: 50.8949291},
{lng: 12.9713576, lat: 50.8944181},
{lng: 12.9727494, lat: 50.8940822},
{lng: 12.9738822, lat: 50.8936472},
{lng: 12.974705, lat: 50.8942241},
{lng: 12.9756288, lat: 50.8947921},
{lng: 12.9785944, lat: 50.8937123},
{lng: 12.9814431, lat: 50.8926594},
{lng: 12.9823209, lat: 50.8927323},
{lng: 12.9828789, lat: 50.8926064},
{lng: 12.9833498, lat: 50.8922594},
{lng: 12.9836568, lat: 50.8925565},
{lng: 12.9841966, lat: 50.8930794},
{lng: 12.9851956, lat: 50.8927134},
{lng: 12.9860184, lat: 50.8922975},
{lng: 12.9862544, lat: 50.8920725},
{lng: 12.9864373, lat: 50.8917746},
{lng: 12.9869643, lat: 50.8910036},
{lng: 12.9870963, lat: 50.8911186},
{lng: 12.9875793, lat: 50.8913966},
{lng: 12.9879732, lat: 50.8917476},
{lng: 12.988221, lat: 50.8919735},
{lng: 12.9893639, lat: 50.8918896},
{lng: 12.9897468, lat: 50.8917325},
{lng: 12.9901319, lat: 50.8917256},
{lng: 12.9898138, lat: 50.8914736},
{lng: 12.9883482, lat: 50.8902757},
{lng: 12.989292, lat: 50.8894137},
{lng: 12.989648, lat: 50.8890588},
{lng: 12.9897919, lat: 50.8889179},
{lng: 12.9903589, lat: 50.8892878},
{lng: 12.9909348, lat: 50.8896608},
{lng: 12.9910238, lat: 50.8894589},
{lng: 12.9907617, lat: 50.8889399},
{lng: 12.9905129, lat: 50.8886729},
{lng: 12.9901189, lat: 50.8882539},
{lng: 12.9885352, lat: 50.8880969},
{lng: 12.9880952, lat: 50.888132},
{lng: 12.9881842, lat: 50.887727},
{lng: 12.9888022, lat: 50.887651},
{lng: 12.9888331, lat: 50.8873571},
{lng: 12.989743, lat: 50.887323},
{lng: 12.9904528, lat: 50.8873801},
{lng: 12.9909458, lat: 50.887552},
{lng: 12.9916807, lat: 50.8874261},
{lng: 12.9914808, lat: 50.8863352},
{lng: 12.9906328, lat: 50.8855533},
{lng: 12.990202, lat: 50.8854232},
{lng: 12.9898719, lat: 50.8853082},
{lng: 12.9887851, lat: 50.8851563},
{lng: 12.9877263, lat: 50.8858351},
{lng: 12.9875424, lat: 50.8850072},
{lng: 12.9874383, lat: 50.8845384},
{lng: 12.9871694, lat: 50.8840993},
{lng: 12.9871329, lat: 50.8840063},
{lng: 12.989496, lat: 50.8824749},
{lng: 12.9899102, lat: 50.8821441},
{lng: 12.9903386, lat: 50.8818113},
{lng: 12.9906693, lat: 50.8816186},
{lng: 12.9949614, lat: 50.878286},
{lng: 12.9957403, lat: 50.8776521},
{lng: 12.9967032, lat: 50.8768662},
{lng: 12.9982418, lat: 50.8756413},
{lng: 12.9986679, lat: 50.875219},
{lng: 12.9995579, lat: 50.8741517},
{lng: 12.9962607, lat: 50.8733503},
{lng: 12.9959274, lat: 50.8740502},
{lng: 12.9956419, lat: 50.8738755},
{lng: 12.9954641, lat: 50.8734714},
{lng: 12.9956293, lat: 50.8732469},
{lng: 12.9958534, lat: 50.8722744},
{lng: 12.995329, lat: 50.8721192},
{lng: 12.9955979, lat: 50.8717149},
{lng: 12.9951717, lat: 50.8716481},
{lng: 12.9947252, lat: 50.8715091},
{lng: 12.9943403, lat: 50.8713186},
{lng: 12.9938857, lat: 50.8719232},
{lng: 12.9931313, lat: 50.8712174},
{lng: 12.9929508, lat: 50.8708124},
{lng: 12.9933939, lat: 50.8703469},
{lng: 12.9925556, lat: 50.8692976},
{lng: 12.9927927, lat: 50.868911},
{lng: 12.9934738, lat: 50.8691952},
{lng: 12.9945904, lat: 50.8692622},
{lng: 12.9957924, lat: 50.8692545},
{lng: 12.9966173, lat: 50.8692592},
{lng: 12.9972417, lat: 50.8692088},
{lng: 12.9962647, lat: 50.8684385},
{lng: 12.996036, lat: 50.8689431},
{lng: 12.9947389, lat: 50.8689318},
{lng: 12.9937126, lat: 50.8688214},
{lng: 12.9930291, lat: 50.86864},
{lng: 12.9930878, lat: 50.8683685},
{lng: 12.9934987, lat: 50.8674649},
{lng: 12.9937119, lat: 50.8671571},
{lng: 12.993196, lat: 50.8668855},
{lng: 12.9930466, lat: 50.8667644},
{lng: 12.9921601, lat: 50.8663479},
{lng: 12.9919551, lat: 50.8662563},
{lng: 12.9920863, lat: 50.8660342},
{lng: 12.9885514, lat: 50.865141},
{lng: 12.9884521, lat: 50.8652386},
{lng: 12.9873539, lat: 50.865025},
{lng: 12.9866667, lat: 50.8648265},
{lng: 12.9866931, lat: 50.8646947},
{lng: 12.9866408, lat: 50.8644848},
{lng: 12.98758, lat: 50.8641171},
{lng: 12.9882494, lat: 50.8639449},
{lng: 12.9872118, lat: 50.8631207},
{lng: 12.9867694, lat: 50.8628357},
{lng: 12.9867831, lat: 50.862436},
{lng: 12.9868677, lat: 50.8622512},
{lng: 12.9873098, lat: 50.8621073},
{lng: 12.9876471, lat: 50.8619442},
{lng: 12.987822, lat: 50.8617422},
{lng: 12.9878955, lat: 50.8615066},
{lng: 12.9878529, lat: 50.8610759},
{lng: 12.9877064, lat: 50.8605222},
{lng: 12.9866925, lat: 50.8606866},
{lng: 12.9867198, lat: 50.8604799},
{lng: 12.98679, lat: 50.8602815},
{lng: 12.9869756, lat: 50.8599903},
{lng: 12.9870739, lat: 50.8598043},
{lng: 12.9871885, lat: 50.8594838},
{lng: 12.9871837, lat: 50.8590457},
{lng: 12.9870123, lat: 50.8581465},
{lng: 12.9863385, lat: 50.8576129},
{lng: 12.9860609, lat: 50.8574014},
{lng: 12.985561, lat: 50.856984},
{lng: 12.9850176, lat: 50.8566273},
{lng: 12.9846063, lat: 50.856392},
{lng: 12.9842517, lat: 50.8561547},
{lng: 12.9832047, lat: 50.8556954},
{lng: 12.9830982, lat: 50.8554052},
{lng: 12.9831526, lat: 50.8552298},
{lng: 12.9832664, lat: 50.8548374},
{lng: 12.98388, lat: 50.8549126},
{lng: 12.9841929, lat: 50.8549523},
{lng: 12.9844297, lat: 50.8549959},
{lng: 12.9844604, lat: 50.854705},
{lng: 12.9844682, lat: 50.8542051},
{lng: 12.9844544, lat: 50.8538326},
{lng: 12.9844538, lat: 50.853432},
{lng: 12.9843129, lat: 50.8528479},
{lng: 12.984297, lat: 50.8525746},
{lng: 12.9842888, lat: 50.8524333},
{lng: 12.9842884, lat: 50.8519792},
{lng: 12.9841874, lat: 50.8516194},
{lng: 12.9840643, lat: 50.8513176},
{lng: 12.9837551, lat: 50.8509278},
{lng: 12.983567, lat: 50.8506452},
{lng: 12.9826279, lat: 50.8496006},
{lng: 12.982509, lat: 50.849465},
{lng: 12.9817789, lat: 50.8487966},
{lng: 12.9812202, lat: 50.8483073},
{lng: 12.9810163, lat: 50.8480926},
{lng: 12.9815426, lat: 50.8479132},
{lng: 12.9822897, lat: 50.847761},
{lng: 12.982723, lat: 50.8477026},
{lng: 12.9830751, lat: 50.8476177},
{lng: 12.9834025, lat: 50.8475116},
{lng: 12.9840544, lat: 50.8473356},
{lng: 12.9841971, lat: 50.847628},
{lng: 12.9843573, lat: 50.8479624},
{lng: 12.9844243, lat: 50.8480865},
{lng: 12.9844991, lat: 50.8482441},
{lng: 12.9846228, lat: 50.8484516},
{lng: 12.9848134, lat: 50.8487762},
{lng: 12.9850634, lat: 50.8491038},
{lng: 12.9853415, lat: 50.849404},
{lng: 12.9860023, lat: 50.85009},
{lng: 12.9865508, lat: 50.8513698},
{lng: 12.9866746, lat: 50.8518256},
{lng: 12.9866801, lat: 50.8522567},
{lng: 12.9870929, lat: 50.8521827},
{lng: 12.9896537, lat: 50.8514873},
{lng: 12.9911298, lat: 50.8512596},
{lng: 12.9927029, lat: 50.8510768},
{lng: 12.993705, lat: 50.8509821},
{lng: 12.9948821, lat: 50.8509139},
{lng: 12.9979183, lat: 50.8508294},
{lng: 12.9988523, lat: 50.850846},
{lng: 12.9998494, lat: 50.8509426},
{lng: 13.0013142, lat: 50.851138},
{lng: 13.0022687, lat: 50.8512992},
{lng: 13.0034622, lat: 50.8514743},
{lng: 13.0038269, lat: 50.8515277},
{lng: 13.0044736, lat: 50.8515942},
{lng: 13.0053554, lat: 50.8517852},
{lng: 13.0063093, lat: 50.8519952},
{lng: 13.0069751, lat: 50.8520672},
{lng: 13.0079191, lat: 50.8519761},
{lng: 13.008458, lat: 50.8518502},
{lng: 13.0090729, lat: 50.8512932},
{lng: 13.0099897, lat: 50.8503854},
{lng: 13.0112881, lat: 50.8493596},
{lng: 13.011787, lat: 50.8498774},
{lng: 13.014355, lat: 50.8486752},
{lng: 13.0163062, lat: 50.8480765},
{lng: 13.0183698, lat: 50.8472991},
{lng: 13.022014, lat: 50.8463494},
{lng: 13.023553, lat: 50.8462273},
{lng: 13.0239515, lat: 50.8465193},
{lng: 13.0244166, lat: 50.8466776},
{lng: 13.0255928, lat: 50.8471813},
{lng: 13.0259301, lat: 50.8472648},
{lng: 13.0261787, lat: 50.8472548},
{lng: 13.0282749, lat: 50.8484383},
{lng: 13.0311106, lat: 50.848059},
{lng: 13.033022, lat: 50.8480668},
{lng: 13.0324206, lat: 50.847114},
{lng: 13.0331104, lat: 50.8464502},
{lng: 13.0333657, lat: 50.8457363},
{lng: 13.0332262, lat: 50.8452404},
{lng: 13.0356707, lat: 50.8440824},
{lng: 13.0361981, lat: 50.8439192},
{lng: 13.0373369, lat: 50.8436403},
{lng: 13.0397806, lat: 50.8431245},
{lng: 13.0416535, lat: 50.84278},
{lng: 13.0429176, lat: 50.8426217},
{lng: 13.043213, lat: 50.8423971},
{lng: 13.0437843, lat: 50.8421768},
{lng: 13.0453485, lat: 50.8419557},
{lng: 13.0461353, lat: 50.8419974},
{lng: 13.0472462, lat: 50.8420214},
{lng: 13.0487272, lat: 50.842375},
{lng: 13.0487949, lat: 50.841143},
{lng: 13.0490331, lat: 50.8407742},
{lng: 13.0494542, lat: 50.8385269},
{lng: 13.0494971, lat: 50.8361053},
{lng: 13.0495877, lat: 50.8340227},
{lng: 13.0487179, lat: 50.832884},
{lng: 13.0479317, lat: 50.831649},
{lng: 13.0477557, lat: 50.8304704},
{lng: 13.0476862, lat: 50.8298295},
{lng: 13.0465608, lat: 50.8279626},
{lng: 13.0455136, lat: 50.8262839},
{lng: 13.044572, lat: 50.8249407},
{lng: 13.0443545, lat: 50.8246468},
{lng: 13.0437888, lat: 50.8228212},
{lng: 13.0433241, lat: 50.8219919},
{lng: 13.0426573, lat: 50.822261},
{lng: 13.0423385, lat: 50.8225651},
{lng: 13.0415716, lat: 50.8221257},
{lng: 13.0412479, lat: 50.8213953},
{lng: 13.0393548, lat: 50.8189133},
{lng: 13.0395869, lat: 50.8183753},
{lng: 13.0410358, lat: 50.8176966},
{lng: 13.0422962, lat: 50.8166288},
{lng: 13.0452779, lat: 50.8152399},
{lng: 13.0469541, lat: 50.8146758},
{lng: 13.0479383, lat: 50.8144986},
{lng: 13.0488617, lat: 50.8143085},
{lng: 13.0495467, lat: 50.8132438},
{lng: 13.049772, lat: 50.8130279},
{lng: 13.049881, lat: 50.812217},
{lng: 13.0499836, lat: 50.8120793},
{lng: 13.049799, lat: 50.8118831},
{lng: 13.0498214, lat: 50.811526},
{lng: 13.0510316, lat: 50.8113789},
{lng: 13.0512888, lat: 50.8109456},
{lng: 13.0527582, lat: 50.8104427},
{lng: 13.0540393, lat: 50.8103252},
{lng: 13.0538806, lat: 50.8093956},
{lng: 13.0537541, lat: 50.8091918},
{lng: 13.0528304, lat: 50.8069575},
{lng: 13.0489307, lat: 50.8067038},
{lng: 13.0482477, lat: 50.8065916},
{lng: 13.0467381, lat: 50.8063901},
{lng: 13.0452154, lat: 50.8063632},
{lng: 13.0447892, lat: 50.8045889},
{lng: 13.0438581, lat: 50.8039506},
{lng: 13.0424773, lat: 50.8035916},
{lng: 13.0429125, lat: 50.802402},
{lng: 13.0418318, lat: 50.8014404},
{lng: 13.0430829, lat: 50.8009798},
{lng: 13.0423143, lat: 50.8002987},
{lng: 13.0404475, lat: 50.7998206},
{lng: 13.0403937, lat: 50.7985464},
{lng: 13.0407948, lat: 50.7984164},
{lng: 13.0408172, lat: 50.7982843},
{lng: 13.0403513, lat: 50.7973642},
{lng: 13.0393614, lat: 50.7967101},
{lng: 13.0391957, lat: 50.7958855},
{lng: 13.0385916, lat: 50.7951128},
{lng: 13.038198, lat: 50.7946096},
{lng: 13.0365204, lat: 50.7933298},
{lng: 13.0355917, lat: 50.7924685},
{lng: 13.0350605, lat: 50.7921629},
{lng: 13.0342653, lat: 50.7919028},
{lng: 13.0333774, lat: 50.7914344},
{lng: 13.0329422, lat: 50.7912906},
{lng: 13.0312386, lat: 50.7908971},
{lng: 13.0309491, lat: 50.7908787},
{lng: 13.0310471, lat: 50.7907024},
{lng: 13.0313578, lat: 50.7893995},
{lng: 13.0318637, lat: 50.7885332},
{lng: 13.0320147, lat: 50.7878648},
{lng: 13.0319118, lat: 50.7867247},
{lng: 13.0311955, lat: 50.7859576},
{lng: 13.0305975, lat: 50.7854917},
{lng: 13.0302054, lat: 50.7855384},
{lng: 13.0293014, lat: 50.7852191},
{lng: 13.028801, lat: 50.7836874},
{lng: 13.0278792, lat: 50.78222},
{lng: 13.0256594, lat: 50.780512},
{lng: 13.0249798, lat: 50.7796187},
{lng: 13.0228373, lat: 50.7772762},
{lng: 13.021671, lat: 50.7767594},
{lng: 13.0220451, lat: 50.7758791},
{lng: 13.02172, lat: 50.7752989},
{lng: 13.0221353, lat: 50.7738083},
{lng: 13.0228094, lat: 50.7730091},
{lng: 13.0220823, lat: 50.7726812},
{lng: 13.0219484, lat: 50.7718415},
{lng: 13.0210892, lat: 50.7714678},
{lng: 13.0197677, lat: 50.7710268},
{lng: 13.0199367, lat: 50.7707393},
{lng: 13.020617, lat: 50.770214},
{lng: 13.0188536, lat: 50.7693705},
{lng: 13.0183257, lat: 50.7692313},
{lng: 13.018003, lat: 50.7691451},
{lng: 13.017857, lat: 50.7690436},
{lng: 13.0176436, lat: 50.7688857},
{lng: 13.0169808, lat: 50.7693822},
{lng: 13.0168822, lat: 50.7691601},
{lng: 13.0145832, lat: 50.7701607},
{lng: 13.0129008, lat: 50.771178},
{lng: 13.0118022, lat: 50.7715457},
{lng: 13.0110735, lat: 50.7715181},
{lng: 13.0106344, lat: 50.7714403},
{lng: 13.0102672, lat: 50.7713645},
{lng: 13.0094955, lat: 50.7714352},
{lng: 13.0088598, lat: 50.7718442},
{lng: 13.0086268, lat: 50.7721818},
{lng: 13.0081812, lat: 50.7724281},
{lng: 13.0068621, lat: 50.7729524},
{lng: 13.0059044, lat: 50.7736834},
{lng: 13.0055139, lat: 50.7744032},
{lng: 13.0049067, lat: 50.7750308},
{lng: 13.0030255, lat: 50.7751935},
{lng: 13.0021367, lat: 50.7756604},
{lng: 13.0008427, lat: 50.7759098},
{lng: 12.9995013, lat: 50.776309},
{lng: 12.9984204, lat: 50.7769327},
{lng: 12.9981238, lat: 50.7772772},
{lng: 12.9968498, lat: 50.7774995},
{lng: 12.9958344, lat: 50.7779652},
{lng: 12.9954663, lat: 50.7784432},
{lng: 12.9945288, lat: 50.7786002},
{lng: 12.9930894, lat: 50.7793834},
{lng: 12.9916525, lat: 50.7797612},
{lng: 12.9901324, lat: 50.7791223},
{lng: 12.9893401, lat: 50.7784026},
{lng: 12.9884934, lat: 50.7773172},
{lng: 12.9880616, lat: 50.7771698},
{lng: 12.9886858, lat: 50.7768088},
{lng: 12.9889228, lat: 50.7769556},
{lng: 12.9895019, lat: 50.7770187},
{lng: 12.9899212, lat: 50.7769429},
{lng: 12.9900809, lat: 50.7768209},
{lng: 12.9902074, lat: 50.7765473},
{lng: 12.9901874, lat: 50.7762863},
{lng: 12.9900676, lat: 50.7759412},
{lng: 12.9897881, lat: 50.7754908},
{lng: 12.9904603, lat: 50.7752509},
{lng: 12.9910328, lat: 50.7751289},
{lng: 12.9914721, lat: 50.7747542},
{lng: 12.9912457, lat: 50.7746069},
{lng: 12.9905269, lat: 50.7748005},
{lng: 12.9895351, lat: 50.7749352},
{lng: 12.9896562, lat: 50.773717},
{lng: 12.9897814, lat: 50.7735083},
{lng: 12.9901275, lat: 50.7734115},
{lng: 12.990487, lat: 50.7732221},
{lng: 12.9906866, lat: 50.7728685},
{lng: 12.9904337, lat: 50.7723845},
{lng: 12.9895169, lat: 50.7718198},
{lng: 12.9895393, lat: 50.771292},
{lng: 12.9903206, lat: 50.7706712},
{lng: 12.9912355, lat: 50.7705363},
{lng: 12.9910261, lat: 50.7696188},
{lng: 12.9900876, lat: 50.7689916},
{lng: 12.9900011, lat: 50.7685875},
{lng: 12.9893171, lat: 50.7677568},
{lng: 12.9896765, lat: 50.7675295},
{lng: 12.9897564, lat: 50.7671843},
{lng: 12.9894236, lat: 50.7669822},
{lng: 12.9880686, lat: 50.7669318},
{lng: 12.9876366, lat: 50.7668851},
{lng: 12.9867611, lat: 50.7666497},
{lng: 12.9861535, lat: 50.7663129},
{lng: 12.985608, lat: 50.765578},
{lng: 12.9855815, lat: 50.7654073},
{lng: 12.9857489, lat: 50.7653852},
{lng: 12.9863325, lat: 50.7654209},
{lng: 12.9870624, lat: 50.765363},
{lng: 12.9871938, lat: 50.7651828},
{lng: 12.9872503, lat: 50.7650101},
{lng: 12.9872079, lat: 50.7648017},
{lng: 12.9864809, lat: 50.7640176},
{lng: 12.9858482, lat: 50.7635923},
{lng: 12.984345, lat: 50.7630915},
{lng: 12.9833325, lat: 50.7630177},
{lng: 12.981454, lat: 50.7624529},
{lng: 12.980688, lat: 50.7622877},
{lng: 12.9802226, lat: 50.7622616},
{lng: 12.9796576, lat: 50.7624278},
{lng: 12.9794529, lat: 50.7621573},
{lng: 12.9792062, lat: 50.7614955},
{lng: 12.9790536, lat: 50.7608521},
{lng: 12.9788273, lat: 50.7598668},
{lng: 12.9786855, lat: 50.7596367},
{lng: 12.9785491, lat: 50.7591455},
{lng: 12.9785726, lat: 50.7587197},
{lng: 12.9785985, lat: 50.7579039},
{lng: 12.9787773, lat: 50.7573695},
{lng: 12.978429, lat: 50.7572429},
{lng: 12.9783137, lat: 50.7573546},
{lng: 12.9777466, lat: 50.7575437},
{lng: 12.9776619, lat: 50.7576836},
{lng: 12.9776713, lat: 50.7578756},
{lng: 12.9776289, lat: 50.7580275},
{lng: 12.9776689, lat: 50.758483},
{lng: 12.9776783, lat: 50.7586483},
{lng: 12.9775324, lat: 50.7589758},
{lng: 12.9777207, lat: 50.7596099},
{lng: 12.9778422, lat: 50.7598332},
{lng: 12.9781084, lat: 50.7605068},
{lng: 12.9780285, lat: 50.76081},
{lng: 12.9772639, lat: 50.7607217},
{lng: 12.97697, lat: 50.7605879},
{lng: 12.9752661, lat: 50.7586426},
{lng: 12.9749961, lat: 50.7584106},
{lng: 12.9747253, lat: 50.7584353},
{lng: 12.9745489, lat: 50.7586584},
{lng: 12.9743006, lat: 50.7588596},
{lng: 12.974038, lat: 50.7589563},
{lng: 12.9736453, lat: 50.7590132},
{lng: 12.9732626, lat: 50.7590026},
{lng: 12.9726699, lat: 50.7588717},
{lng: 12.971692, lat: 50.7577262},
{lng: 12.9717807, lat: 50.7568644},
{lng: 12.9716326, lat: 50.7564286},
{lng: 12.9712889, lat: 50.7560828},
{lng: 12.9683597, lat: 50.7551694},
{lng: 12.9679687, lat: 50.7546431},
{lng: 12.9684984, lat: 50.7542043},
{lng: 12.9663719, lat: 50.7532104},
{lng: 12.9657753, lat: 50.7537167},
{lng: 12.9654159, lat: 50.7541045},
{lng: 12.9649823, lat: 50.753745},
{lng: 12.9645889, lat: 50.7532191},
{lng: 12.964813, lat: 50.7529835},
{lng: 12.9636353, lat: 50.7525377},
{lng: 12.9642847, lat: 50.7520399},
{lng: 12.9631203, lat: 50.751791},
{lng: 12.9647367, lat: 50.7506178},
{lng: 12.9638036, lat: 50.7502947},
{lng: 12.9620605, lat: 50.7516092},
{lng: 12.9609598, lat: 50.7528154},
{lng: 12.9597368, lat: 50.7540402},
{lng: 12.9590948, lat: 50.7545461},
{lng: 12.9583709, lat: 50.7554804},
{lng: 12.9575916, lat: 50.7561926},
{lng: 12.9559742, lat: 50.7570945},
{lng: 12.9522029, lat: 50.7587713},
{lng: 12.9470749, lat: 50.7574822},
{lng: 12.9443681, lat: 50.7567925},
{lng: 12.9442893, lat: 50.7567723},
{lng: 12.9408063, lat: 50.7545228},
{lng: 12.9375372, lat: 50.753799},
{lng: 12.936343, lat: 50.7533492},
{lng: 12.9371453, lat: 50.7527013},
{lng: 12.9378259, lat: 50.751439},
{lng: 12.9379442, lat: 50.7511602},
{lng: 12.9385028, lat: 50.750778},
{lng: 12.9381342, lat: 50.7497941},
{lng: 12.937636, lat: 50.7488616},
{lng: 12.9376452, lat: 50.747096},
{lng: 12.9387044, lat: 50.7467589},
{lng: 12.9367177, lat: 50.7434321},
{lng: 12.9350763, lat: 50.7432174},
{lng: 12.9335393, lat: 50.7427681},
{lng: 12.9307959, lat: 50.742238},
{lng: 12.9282049, lat: 50.7416277},
{lng: 12.9260231, lat: 50.7414253},
{lng: 12.9241578, lat: 50.7413803},
{lng: 12.9235944, lat: 50.7424916},
{lng: 12.921157, lat: 50.7427207},
{lng: 12.9211696, lat: 50.7430439},
{lng: 12.9194542, lat: 50.7436337},
{lng: 12.9194015, lat: 50.7434364},
{lng: 12.9167475, lat: 50.7437976},
{lng: 12.9168303, lat: 50.7433967},
{lng: 12.9158338, lat: 50.7434334},
{lng: 12.9158741, lat: 50.7437633},
{lng: 12.9158946, lat: 50.7441258},
{lng: 12.9147696, lat: 50.7442107},
{lng: 12.914789, lat: 50.7440479},
{lng: 12.9148278, lat: 50.743722},
{lng: 12.9147931, lat: 50.7432932},
{lng: 12.9131562, lat: 50.7436962},
{lng: 12.9122463, lat: 50.7439854},
{lng: 12.911348, lat: 50.744093},
{lng: 12.9081703, lat: 50.7459287},
{lng: 12.9077012, lat: 50.7463799},
{lng: 12.9064431, lat: 50.7477766},
{lng: 12.9057803, lat: 50.7482917},
{lng: 12.905288, lat: 50.7485336},
{lng: 12.9049805, lat: 50.7488478},
{lng: 12.9046059, lat: 50.7493356},
{lng: 12.9044474, lat: 50.7494222},
{lng: 12.9040512, lat: 50.7495817},
{lng: 12.9035973, lat: 50.7498735},
{lng: 12.9034964, lat: 50.7500285},
{lng: 12.9035181, lat: 50.7501516},
{lng: 12.9011766, lat: 50.7506621},
{lng: 12.8999662, lat: 50.7511271},
{lng: 12.8981795, lat: 50.7516603},
{lng: 12.8978265, lat: 50.7518518},
{lng: 12.8975095, lat: 50.7519475},
{lng: 12.8967747, lat: 50.7523714},
{lng: 12.8960902, lat: 50.7527224},
{lng: 12.8959778, lat: 50.7530506},
{lng: 12.8952113, lat: 50.7540277},
{lng: 12.8949304, lat: 50.754336},
{lng: 12.8941645, lat: 50.7552378},
{lng: 12.894054, lat: 50.7554478},
{lng: 12.8939145, lat: 50.755913},
{lng: 12.893547, lat: 50.7566149},
{lng: 12.8933597, lat: 50.758128},
{lng: 12.8925456, lat: 50.7595453},
{lng: 12.8921421, lat: 50.7607439},
{lng: 12.8921421, lat: 50.7621157},
{lng: 12.8920052, lat: 50.7624666},
{lng: 12.8915369, lat: 50.7631591},
{lng: 12.8896998, lat: 50.7626169},
{lng: 12.8879131, lat: 50.7622569},
{lng: 12.8842676, lat: 50.7619196},
{lng: 12.8811896, lat: 50.761685},
{lng: 12.8805756, lat: 50.7637713},
{lng: 12.8805616, lat: 50.7638096},
{lng: 12.8805201, lat: 50.7639226},
{lng: 12.8801683, lat: 50.7660528},
{lng: 12.8806834, lat: 50.7665291},
{lng: 12.8814285, lat: 50.7672557},
{lng: 12.8824774, lat: 50.7682787},
{lng: 12.8825998, lat: 50.7683379},
{lng: 12.883486, lat: 50.7681079},
{lng: 12.8839146, lat: 50.7682491},
{lng: 12.8849124, lat: 50.769108},
{lng: 12.8855752, lat: 50.7695659},
{lng: 12.885496, lat: 50.7697094},
{lng: 12.8853988, lat: 50.7697392},
{lng: 12.8855631, lat: 50.7698157},
{lng: 12.8858618, lat: 50.7697696},
{lng: 12.8860387, lat: 50.7697664},
{lng: 12.8862181, lat: 50.7697824},
{lng: 12.887143, lat: 50.7698111},
{lng: 12.8871481, lat: 50.7698319},
{lng: 12.8868625, lat: 50.7698415},
{lng: 12.8865795, lat: 50.7699166},
{lng: 12.8864152, lat: 50.7700173},
{lng: 12.8879189, lat: 50.770222},
{lng: 12.8894074, lat: 50.7704234},
{lng: 12.8893694, lat: 50.7696193},
{lng: 12.88942, lat: 50.7696209},
{lng: 12.8894073, lat: 50.7698111},
{lng: 12.8894479, lat: 50.7698144},
{lng: 12.8896034, lat: 50.7704541},
{lng: 12.8901319, lat: 50.7705366},
{lng: 12.8925384, lat: 50.7707876},
{lng: 12.8927305, lat: 50.7706949},
{lng: 12.8930286, lat: 50.7705607},
{lng: 12.8934229, lat: 50.7704456},
{lng: 12.8938529, lat: 50.7703862},
{lng: 12.8938879, lat: 50.7696849},
{lng: 12.8943681, lat: 50.7697073},
{lng: 12.8942973, lat: 50.7703753},
{lng: 12.8943832, lat: 50.7703785},
{lng: 12.8948128, lat: 50.7706118},
{lng: 12.8949948, lat: 50.7707621},
{lng: 12.8960864, lat: 50.7717785},
{lng: 12.8973299, lat: 50.7731626},
{lng: 12.8921239, lat: 50.7751537},
{lng: 12.892225, lat: 50.775403},
{lng: 12.8923963, lat: 50.7761208},
{lng: 12.8929024, lat: 50.7761062},
{lng: 12.8942063, lat: 50.777538},
{lng: 12.8946814, lat: 50.7789473},
{lng: 12.8947446, lat: 50.7791998},
{lng: 12.8947041, lat: 50.7799237},
{lng: 12.8946811, lat: 50.7802411},
{lng: 12.8946056, lat: 50.7804318},
{lng: 12.8942, lat: 50.7804949},
{lng: 12.8940344, lat: 50.7805492},
{lng: 12.8941974, lat: 50.7828295},
{lng: 12.8924313, lat: 50.7834949},
{lng: 12.887957, lat: 50.7852647},
{lng: 12.8868331, lat: 50.7850167},
{lng: 12.8857994, lat: 50.7845507},
{lng: 12.8855084, lat: 50.7839297},
{lng: 12.8851275, lat: 50.7833108},
{lng: 12.8848204, lat: 50.7831468},
{lng: 12.8844435, lat: 50.7829379},
{lng: 12.8835496, lat: 50.782453},
{lng: 12.8844236, lat: 50.782327},
{lng: 12.8843276, lat: 50.782014},
{lng: 12.8842356, lat: 50.7817131},
{lng: 12.8841947, lat: 50.781576},
{lng: 12.8838977, lat: 50.7813621},
{lng: 12.8835428, lat: 50.7812741},
{lng: 12.8829508, lat: 50.7811141},
{lng: 12.8821331, lat: 50.7810341},
{lng: 12.8821761, lat: 50.7816469},
{lng: 12.8812954, lat: 50.7816435},
{lng: 12.8806322, lat: 50.781549},
{lng: 12.8783194, lat: 50.7814609},
{lng: 12.8781605, lat: 50.7810261},
{lng: 12.8777996, lat: 50.7800462},
{lng: 12.8776577, lat: 50.7796824},
{lng: 12.8767385, lat: 50.77958},
{lng: 12.8767577, lat: 50.7802101},
{lng: 12.8766775, lat: 50.7804779},
{lng: 12.8761937, lat: 50.7804567},
{lng: 12.8759498, lat: 50.7804567},
{lng: 12.8755302, lat: 50.7804178},
{lng: 12.8755529, lat: 50.7801301},
{lng: 12.875581, lat: 50.7794702},
{lng: 12.8745143, lat: 50.7794951},
{lng: 12.8744201, lat: 50.7794131},
{lng: 12.8730141, lat: 50.7794982},
{lng: 12.8712456, lat: 50.7797095},
{lng: 12.8706552, lat: 50.7798878},
{lng: 12.8701069, lat: 50.7800412},
{lng: 12.8692596, lat: 50.7802948},
{lng: 12.8692503, lat: 50.7804651},
{lng: 12.8690107, lat: 50.7814663},
{lng: 12.8688414, lat: 50.7821773},
{lng: 12.8693036, lat: 50.7823703},
{lng: 12.8692237, lat: 50.7826111},
{lng: 12.8696966, lat: 50.7829417},
{lng: 12.8713851, lat: 50.7833725},
{lng: 12.8723632, lat: 50.7839716},
{lng: 12.8726568, lat: 50.7841528},
{lng: 12.8729998, lat: 50.7844589},
{lng: 12.8724876, lat: 50.7846776},
{lng: 12.8722371, lat: 50.7847955},
{lng: 12.8720006, lat: 50.7848945},
{lng: 12.8734063, lat: 50.7861611},
{lng: 12.8743061, lat: 50.7869761},
{lng: 12.8753981, lat: 50.7879496},
{lng: 12.8759086, lat: 50.7886574},
{lng: 12.87556, lat: 50.7887209},
{lng: 12.8742087, lat: 50.7893194},
{lng: 12.8739991, lat: 50.7894287},
{lng: 12.8731804, lat: 50.7901251},
{lng: 12.8729628, lat: 50.7903658},
{lng: 12.8728542, lat: 50.7904528},
{lng: 12.8726879, lat: 50.7905348},
{lng: 12.8716756, lat: 50.7905384},
{lng: 12.8708803, lat: 50.7905436},
{lng: 12.86975, lat: 50.7906449},
{lng: 12.8677878, lat: 50.7907397},
{lng: 12.8669659, lat: 50.7907555},
{lng: 12.8663341, lat: 50.7907945},
{lng: 12.865764, lat: 50.7909284},
{lng: 12.8629867, lat: 50.7917914},
{lng: 12.8618062, lat: 50.7921339},
{lng: 12.8612851, lat: 50.7923119},
{lng: 12.8600051, lat: 50.7929244},
{lng: 12.8593939, lat: 50.7932353},
{lng: 12.8587871, lat: 50.7929434},
{lng: 12.8585803, lat: 50.7933585},
{lng: 12.8583215, lat: 50.7936832},
{lng: 12.8579416, lat: 50.7939654},
{lng: 12.8576584, lat: 50.794132},
{lng: 12.8573699, lat: 50.7943007},
{lng: 12.8569901, lat: 50.7944411},
{lng: 12.8567115, lat: 50.7944987},
{lng: 12.8560639, lat: 50.7945788},
{lng: 12.8556027, lat: 50.7946168},
{lng: 12.8551059, lat: 50.7946578},
{lng: 12.8547375, lat: 50.7947138},
{lng: 12.8543841, lat: 50.794777},
{lng: 12.8537372, lat: 50.7949477},
{lng: 12.8529821, lat: 50.7952553},
{lng: 12.851455, lat: 50.7957084},
{lng: 12.8505748, lat: 50.7960953},
{lng: 12.8502662, lat: 50.7962323},
{lng: 12.8495928, lat: 50.7965568},
{lng: 12.8491043, lat: 50.7967707},
{lng: 12.8486726, lat: 50.7969056},
{lng: 12.8473039, lat: 50.7972049},
{lng: 12.846817, lat: 50.797344},
{lng: 12.8457734, lat: 50.7977212},
{lng: 12.844533, lat: 50.7989267},
{lng: 12.8443129, lat: 50.7990194},
{lng: 12.8436495, lat: 50.7995072},
{lng: 12.8433426, lat: 50.7997412},
{lng: 12.8427091, lat: 50.8002638},
{lng: 12.8419874, lat: 50.8009919},
{lng: 12.8419289, lat: 50.8011088},
{lng: 12.8415339, lat: 50.801388},
{lng: 12.8410487, lat: 50.8018232},
{lng: 12.8407319, lat: 50.8019475},
{lng: 12.8406502, lat: 50.8020127},
{lng: 12.8400003, lat: 50.8015634},
{lng: 12.8394674, lat: 50.8008619},
{lng: 12.8391576, lat: 50.800678},
{lng: 12.8390674, lat: 50.8006404},
{lng: 12.8381857, lat: 50.8003703},
{lng: 12.8378878, lat: 50.8002359},
{lng: 12.8376273, lat: 50.8001067},
{lng: 12.8372699, lat: 50.7999604},
{lng: 12.8366775, lat: 50.7997193},
{lng: 12.8366383, lat: 50.799685},
{lng: 12.8366281, lat: 50.7995828},
{lng: 12.8366742, lat: 50.7993052},
{lng: 12.8366758, lat: 50.7992213},
{lng: 12.8366366, lat: 50.7991374},
{lng: 12.8365787, lat: 50.7990094},
{lng: 12.8362172, lat: 50.7987449},
{lng: 12.8355326, lat: 50.7983352},
{lng: 12.8352139, lat: 50.7982668},
{lng: 12.8349824, lat: 50.798177},
{lng: 12.8347902, lat: 50.7981892},
{lng: 12.8347311, lat: 50.7981628},
{lng: 12.8346303, lat: 50.7982001},
{lng: 12.8343817, lat: 50.7981958},
{lng: 12.8339265, lat: 50.7980398},
{lng: 12.8336969, lat: 50.7979398},
{lng: 12.8335579, lat: 50.7978475},
{lng: 12.8326402, lat: 50.7975663},
{lng: 12.831952, lat: 50.7971664},
{lng: 12.8318007, lat: 50.7970269},
{lng: 12.831726, lat: 50.7969291},
{lng: 12.8317034, lat: 50.7966391},
{lng: 12.8311195, lat: 50.7962875},
{lng: 12.8306953, lat: 50.7959898},
{lng: 12.8301253, lat: 50.7957328},
{lng: 12.8295552, lat: 50.7955339},
{lng: 12.8292753, lat: 50.7954691},
{lng: 12.8289156, lat: 50.795425},
{lng: 12.8287157, lat: 50.7954306},
{lng: 12.8284185, lat: 50.7953274},
{lng: 12.8270332, lat: 50.7947956},
{lng: 12.8263798, lat: 50.7945342},
{lng: 12.8259869, lat: 50.7944243},
{lng: 12.8256777, lat: 50.7943572},
{lng: 12.8253056, lat: 50.7943429},
{lng: 12.8249459, lat: 50.7943793},
{lng: 12.8240873, lat: 50.7944041},
{lng: 12.8232392, lat: 50.7944033},
{lng: 12.8227211, lat: 50.7944682},
{lng: 12.8222727, lat: 50.7946637},
{lng: 12.8223283, lat: 50.7945682},
{lng: 12.8223266, lat: 50.7945067},
{lng: 12.8223214, lat: 50.7943716},
{lng: 12.8225438, lat: 50.7939189},
{lng: 12.8225491, lat: 50.7938025},
{lng: 12.8226099, lat: 50.7934641},
{lng: 12.8227124, lat: 50.7932191},
{lng: 12.8228671, lat: 50.7928994},
{lng: 12.8228515, lat: 50.7928631},
{lng: 12.8227402, lat: 50.7928247},
{lng: 12.8225977, lat: 50.7927642},
{lng: 12.8225856, lat: 50.792717},
{lng: 12.8225352, lat: 50.7926719},
{lng: 12.8225856, lat: 50.7925577},
{lng: 12.8224743, lat: 50.792483},
{lng: 12.8224535, lat: 50.7924214},
{lng: 12.8223839, lat: 50.7924105},
{lng: 12.8223462, lat: 50.7923504},
{lng: 12.8222727, lat: 50.7922841},
{lng: 12.8221024, lat: 50.7922138},
{lng: 12.8218869, lat: 50.7921622},
{lng: 12.8216939, lat: 50.7921358},
{lng: 12.8215983, lat: 50.7921413},
{lng: 12.8215097, lat: 50.7921149},
{lng: 12.8208562, lat: 50.7918326},
{lng: 12.8207477, lat: 50.7917464},
{lng: 12.8205607, lat: 50.7915018},
{lng: 12.8205051, lat: 50.79117},
{lng: 12.8204756, lat: 50.7911085},
{lng: 12.8202044, lat: 50.7909085},
{lng: 12.8190973, lat: 50.7904668},
{lng: 12.8189478, lat: 50.7903812},
{lng: 12.8184751, lat: 50.7898856},
{lng: 12.8184055, lat: 50.7897659},
{lng: 12.8184073, lat: 50.7896637},
{lng: 12.8184959, lat: 50.7896076},
{lng: 12.8180823, lat: 50.789445},
{lng: 12.818011, lat: 50.789467},
{lng: 12.8179085, lat: 50.7893945},
{lng: 12.8178806, lat: 50.7894154},
{lng: 12.8177931, lat: 50.7893513},
{lng: 12.817792, lat: 50.7890715},
{lng: 12.8175643, lat: 50.7888638},
{lng: 12.8171993, lat: 50.7886682},
{lng: 12.8169855, lat: 50.7883902},
{lng: 12.816864, lat: 50.7883232},
{lng: 12.8153184, lat: 50.7881923},
{lng: 12.8129667, lat: 50.7885523},
{lng: 12.8098157, lat: 50.7885698},
{lng: 12.8055963, lat: 50.7881414},
{lng: 12.8047539, lat: 50.7879541},
{lng: 12.8033613, lat: 50.7872508},
{lng: 12.8019081, lat: 50.7870942},
{lng: 12.7994465, lat: 50.7864496},
{lng: 12.7968612, lat: 50.7856156},
{lng: 12.7931724, lat: 50.7843027},
{lng: 12.7927411, lat: 50.7845455},
{lng: 12.7922916, lat: 50.7847628},
{lng: 12.7911669, lat: 50.7848586},
{lng: 12.7900156, lat: 50.7847728},
{lng: 12.7878307, lat: 50.7856324},
{lng: 12.781911, lat: 50.7857928},
{lng: 12.7783648, lat: 50.7868397},
{lng: 12.777487, lat: 50.7867395},
{lng: 12.7784185, lat: 50.7881738},
{lng: 12.779259, lat: 50.789553},
{lng: 12.7788632, lat: 50.7900349},
{lng: 12.7786247, lat: 50.7907166},
{lng: 12.778341, lat: 50.7916691},
{lng: 12.7778232, lat: 50.7923176},
{lng: 12.7777729, lat: 50.7939647},
{lng: 12.7771835, lat: 50.7954129},
{lng: 12.7769932, lat: 50.7975611},
{lng: 12.7761336, lat: 50.7991032},
{lng: 12.7760483, lat: 50.8001884},
{lng: 12.7756625, lat: 50.8011843},
{lng: 12.7753053, lat: 50.801461},
{lng: 12.7750936, lat: 50.8016248},
{lng: 12.7743826, lat: 50.8017704},
{lng: 12.7737387, lat: 50.8020142},
{lng: 12.7731734, lat: 50.8022078},
{lng: 12.7727259, lat: 50.8022539},
{lng: 12.7721576, lat: 50.8023736},
{lng: 12.7718262, lat: 50.8024494},
{lng: 12.7713192, lat: 50.8024966},
{lng: 12.770551, lat: 50.8027715},
{lng: 12.7707332, lat: 50.8029832},
{lng: 12.7699486, lat: 50.8032812},
{lng: 12.7686617, lat: 50.8036559},
{lng: 12.7670368, lat: 50.8039021},
{lng: 12.7669409, lat: 50.8039404},
{lng: 12.766618, lat: 50.8041738},
{lng: 12.7674375, lat: 50.8055083},
{lng: 12.7679044, lat: 50.8062975},
{lng: 12.7685505, lat: 50.8073773},
{lng: 12.7686031, lat: 50.8081705},
{lng: 12.7685402, lat: 50.8084873},
{lng: 12.7686747, lat: 50.8090666},
{lng: 12.7685627, lat: 50.8098875},
{lng: 12.7689972, lat: 50.8113107},
{lng: 12.768217, lat: 50.8115357},
{lng: 12.7678402, lat: 50.811395},
{lng: 12.7674477, lat: 50.8109912},
{lng: 12.7666149, lat: 50.8116153},
{lng: 12.767384, lat: 50.8119716},
{lng: 12.7666715, lat: 50.812287},
{lng: 12.7657406, lat: 50.8123504},
{lng: 12.7640003, lat: 50.8127031},
{lng: 12.7626332, lat: 50.8133719},
{lng: 12.7615254, lat: 50.8129466},
{lng: 12.7597292, lat: 50.8130218},
{lng: 12.7586063, lat: 50.8148156},
{lng: 12.758057, lat: 50.814477},
{lng: 12.7574208, lat: 50.8145729},
{lng: 12.7572783, lat: 50.8147736},
{lng: 12.7558086, lat: 50.8153776},
{lng: 12.7542756, lat: 50.8166033},
{lng: 12.7529304, lat: 50.8178763},
{lng: 12.750659, lat: 50.8183819},
{lng: 12.7501181, lat: 50.8185199},
{lng: 12.7486977, lat: 50.8188836},
{lng: 12.7477364, lat: 50.8191297},
{lng: 12.7465826, lat: 50.8193548},
{lng: 12.7476986, lat: 50.8214168},
{lng: 12.7447327, lat: 50.8224207},
{lng: 12.7416359, lat: 50.8224706},
{lng: 12.7387121, lat: 50.8212091},
{lng: 12.7399645, lat: 50.8199665},
{lng: 12.7392435, lat: 50.8196864},
{lng: 12.7385018, lat: 50.8191937},
{lng: 12.7373397, lat: 50.818347},
{lng: 12.7364506, lat: 50.8181552},
{lng: 12.7336265, lat: 50.8176323},
{lng: 12.731785, lat: 50.8172033},
{lng: 12.72958, lat: 50.8165705},
{lng: 12.7287238, lat: 50.8162496},
{lng: 12.727798, lat: 50.8160287},
{lng: 12.7276552, lat: 50.8162625},
{lng: 12.7275333, lat: 50.8165949},
{lng: 12.7275907, lat: 50.8170908},
];
